// noinspection JSUnresolvedVariable
/**
 * Items are structured as:
 * {
 *   '<sessionId>': {
 *     sessionId,
 *     title,
 *     sessionDate,
 *     productType,
 *     credits,
 *     attendees: [<personId>]
 *   }
 * }
 * ----
 * Attendees are structured as:
 * {
 *   <personId>: {
 *    personId,
 *    email
 *    firstName,
 *    lastName
 *   }
 * }
 */
import LocalStorageUtil from '../utils/LocalStorageUtil';
import { cartAdd, newSubOrder, orderCreated, subscriptionCreated, } from '@/services/iterable';
import { eCommerceNull, eecAdd, eecPurchase, eecRemove, } from '@/services/gtmManager';
// putting cart out here so it can be used in the `clear` and `state` methods without duplicating data
const BASE_CART = {
  itemCount: 0,
  items: [],
  itemNames: [],
  discountCodes: [],
  totalAttendees: 0,
  attendeeMap: {},
  userAgent: '',
  ipAddress: '',
  cookies: {},
  sourceUrl: '',
};
export const state = () => {
  return {
    cart: BASE_CART,
    billing: {},
    account: {},
    error: {},
  };
};

// basic list of getters for data
export const getters = {
  cartCount: (state) => {
    return state.cart.itemCount;
  },
  cartItems: (state) => {
    return Object.values(state.cart.items);
  },
  cartItemNames: (state) => {
    return Object.values(state.cart.itemNames);
  },
  attendee: state => (personId) => {
    return state.cart.attendeeMap[personId];
  },
  subTotal: (state) => {
    return state.cart.subTotal;
  },
  discountCodes: (state) => {
    return state.cart.discountCodes || [];
  },
  discountTotal: (state) => {
    return state.cart.discountTotal || 0;
  },
  estimatedTax: (state) => {
    return state.cart.estimatedTax;
  },
  total: (state) => {
    return state.cart.total;
  },
  billing: (state) => {
    return state.billing;
  },
  account: (state) => {
    return state.account;
  },
};

// list of action methods to use in components
export const actions = {
  initializeCart ({ commit }) {
    if (!LocalStorageUtil.check('cart')) {
      LocalStorageUtil.set('cart', BASE_CART);
    }
  },
  loadCookieData ({ commit }) {
    commit('updateCart', this.$cookies.get('cart') || BASE_CART);
    state.cart.items = localStorage.getObject('cart.items');
  },
  updateAccount ({ commit }, newAccount) {
    commit('updateAccount', newAccount);
  },
  currentAccount ({ commit }, loadedUser) {
    commit('currentAccount', loadedUser);
  },
  updateBilling ({ commit }, newBilling) {
    commit('updateBilling', newBilling);
  },
  async removeItem ({
    dispatch,
    commit,
  }, sessionId) {
    commit('removeItem', sessionId);
    await dispatch('updateCart');
    commit('save');
  },
  async removeDiscount ({
    dispatch,
    commit,
  }, discount) {
    commit('removeDiscount', discount);
    await dispatch('updateCart');
  },
  async addDiscount ({
    dispatch,
    commit,
  }, discount) {
    commit('addDiscount', discount);
    await dispatch('updateCart');
  },
  async addItem ({
    dispatch,
    commit,
    state,
    rootState,
  }, item) {
    const id = item.id;
    console.log('CART STORE');
    console.log(id);
    console.log(JSON.stringify(state.cart));
    if (state.cart.itemNames !== undefined && state.cart.itemNames.includes(id)) {
      return;
    }
    commit('setItem', { sessionId: id, ...item });
    console.log('CART UPDATE');
    console.log(JSON.stringify(state.cart));
    await dispatch('updateCart');
    const products = state.cart.items.filter(item => item.sessionId === id);
    const cartItem = products[0].sessionId;
    const itemType = cartItem.startsWith('SUB') ? 'SUBSCRIPTION' : 'COURSE';
    const productList = [
      {
        id: products[0].courseId,
        name: products[0].title,
        category: itemType,
        variant: id,
        brand: 'Summit',
        quantity: 1,
      },
    ];
    switch (rootState.search.pageView) {
      case 'COURSES':
        this.getPage = 'course';
        break;
      case 'CORE100':
        this.getPage = 'Core100 Page';
        break;
      default:
        this.getPage = 'Subscription Page';
    }
    eecAdd(this.$gtm, this.getPage, productList);
    if (this.$auth.loggedIn) {
      cartAdd(itemType, cartItem, this.$auth.user.email);
    }
  },
  // idea for the `updateCart` is that we want to modify the cart state, then send it to the API so
  // it can validate the changes. The cart that comes back has more data and re-calculates all the
  // totals and taxes, and used as the cart state. We do this approach on any actions that change
  // the cart and the structure of the cart. The `updateCart` also does a `save` so it is there on
  // the next page refresh.
  async updateCart ({
    commit,
    state,
  }) {
    try {
      console.log('PRE CHECKOUT/CART POST');
      console.log(JSON.stringify(state.cart));
      console.log(JSON.stringify(state.billing));
      // if we have billing we want to send it for visual tax update purposes - billing gets updated in /cart mounted
      const cartData = Object.keys(state.billing).length > 0
        ? {
            ...state.cart,
            billing: {
              ...state.billing,
              state: state.billing.state.id,
            },
          }
        : {
            ...state.cart,
          };
      const newCartDetails = await this.$axios.$post('/checkout/cart', cartData);
      console.log('POST CHECKOUT/CART POST');
      console.log(newCartDetails);
      console.log(JSON.stringify(state.cart));
      await commit('updateCart', newCartDetails);
      console.log('POST `updateCart` ACTION');
      console.log(JSON.stringify(state.cart));
      await commit('save');
      console.log('POST `save` ACTION');
      console.log(JSON.stringify(state.cart));
    } catch (e) {
      console.log('Error updating cart - ' + JSON.stringify(e));
      throw new Error('Error updating cart');
    }
  },
  async updateTax ({
    commit,
    state,
  }) {
    try {
      const cartData = {
        ...state.cart,
        billing: {
          ...state.billing,
          state: state.billing.state.id,
        },
      };
      const newCartDetails = await this.$axios.$post('/checkout/cart', cartData);
      await commit('updateCart', newCartDetails);
      await commit('save');
    } catch (e) {
      console.log('Error updating tax - ' + JSON.stringify(e));
      throw new Error('Error updating tax');
    }
  },
  async checkout ({
    commit,
    state,
  }, token) {
    try {
      let checkoutData;
      try {
        const getIpAddress = await this.$axios.$get('https://api.ipify.org?format=json');
        state.cart.ipAddress = getIpAddress.ip;
      } catch (e) {
        state.cart.ipAddress = '127.0.0.1';
      }
      try {
        state.cart.cookies = this.$cookies.getAll();
        state.cart.userAgent = window.navigator.userAgent.toLowerCase();
        state.cart.sourceUrl = '/cart';
        console.log('Cart details:', state.cart);
      } catch (e) {
        console.error('Failed to set cart details:', e);
      }
      try {
        checkoutData = Object.freeze({
          ...state.cart,
          token,
          billing: {
            ...state.billing,
            state: state.billing.state.id,
          },
          account: {
            ...state.account,
            state: state.billing.state.id,
            profession: state.account.profession.id,
          },
        });
        console.log('Checkout data prepared:', checkoutData);
      } catch (e) {
        console.error('Failed to prepare checkout data:', e);
      }
      const data = await this.$axios.$post('/checkout', checkoutData).then((response) => {
        eCommerceNull(this.$gtm);
        // Google Tag Manager (GTM)
        // const cartItem = state.cart.items[0].sessionId;
        // const checkoutType = cartItem.startsWith('SUB') ? 'SUBSCRIPTION' : 'COURSE';
        // if ((state.cart.discountCodes !== undefined) && (state.cart.discountCodes[0]).startsWith('sgl')) {
        //   console.log('sgl discount');
        //   // this.$gtm.push({
        //   //   event: 'b2bReward',
        //   //   type: checkoutType,
        //   //   sglCode: state.cart.discountCodes,
        //   //   sessions: state.cart.invoices,
        //   //   gclid: this.$route.params.gclid,
        //   // });
        // }
        const productsList = [];
        const getSubtotal = state.cart.subTotal - state.cart.discountTotal;
        const getRevenue = Number(getSubtotal.toFixed(2));
        (state.cart.items).forEach((item) => {
          const getDiscountByItem = state.cart.discountTotal > 0 ? state.cart.discountTotal / state.cart.itemCount : Number(0);
          const getItemDiscountTotal = item.price - getDiscountByItem;
          const fixPriceDec = Number(getItemDiscountTotal.toFixed(2));
          const cartItem = item.sessionId;
          const itemType = cartItem.startsWith('SUB') ? 'SUBSCRIPTION' : 'COURSE';
          orderCreated(state.account.email);
          if (itemType === 'SUBSCRIPTION') {
            try {
              const iterableOrderData = {
                email: state.account.email,
                first_name: state.account.firstName,
                last_name: state.account.lastName,
                phonenumber: state.account.phone,
                eventName: 'new_sub_order',
              };
              newSubOrder(iterableOrderData);
              const iterableSubData = {
                email: state.account.email,
                first_name: state.account.firstName,
                last_name: state.account.lastName,
                phonenumber: state.account.phone,
                eventName: 'subscription_created',
              };
              subscriptionCreated(iterableSubData);
            } catch (e) {
              console.error('Failed to send iterable subscription data:', e);
            }
          }
          productsList.push({
            id: item.sessionId,
            name: item.title,
            category: itemType,
            variant: item.productType,
            brand: 'Summit',
            quantity: 1,
            price: fixPriceDec,
          });
        });
        const discountCodes = state.cart.discountCodes?.length < 1 ? '' : state.cart.discountCodes;
        let action;
        try {
          const discountCodes = state.cart.discountCodes?.length < 1 ? '' : state.cart.discountCodes;
          action = {
            id: response.invoices[0].personId,
            affiliation: 'Summit Website',
            revenue: getRevenue,
            tax: state.cart.estimatedTax,
            coupon: discountCodes,
          };
        } catch (e) {
          console.error('Failed to create action object:', e);
          action = {
            id: '0',
            affiliation: 'Summit Website',
            revenue: getRevenue,
            tax: state.cart.estimatedTax,
            coupon: discountCodes,
          };
        }
        eecPurchase(this.$gtm, action, productsList);
        console.log('eecPurchase called');
      });
      console.log('Post to /checkout complete - ' + JSON.stringify(data));
      await commit('clear', BASE_CART);
      await commit('save');
      return checkoutData;
    } catch (err) {
      console.log('Checkout error- ' + JSON.stringify(err));
      throw new Error(err.response?.data?.message ?? 'err.response.data.message missing');
    }
  },
  async removeAttendee ({
    commit,
    dispatch,
  }, data) {
    await commit('removeAttendee', data);
    await dispatch('updateCart');
  },
  async addAttendee ({
    commit,
    dispatch,
  }, data) {
    commit('addAttendee', data);
    await dispatch('updateCart');
  },
};

const cookieOptions = {};
export const mutations = {
  clear (state) {
    LocalStorageUtil.remove('cart');
    state.cart = BASE_CART;
    LocalStorageUtil.set('cart', state.cart);
  },
  async save (state) {
    await LocalStorageUtil.set('cart', state.cart);
    await this.$cookies.setAll([
      {
        name: 'cart',
        value: state.cart,
        opts: cookieOptions,
      },
    ]);
  },
  refreshCart (state) {
    const cart = LocalStorageUtil.get('cart');
    if (cart) {
      state.cart = {
        ...state.cart,
        ...cart,
      };
    }
  },
  removeItem (state, sessionId) {
    const products = state.cart.items.filter(item => item.sessionId === sessionId);
    console.log(sessionId + 'REMOVED');
    state.cart.items = state.cart.items.filter(item => item.sessionId !== sessionId);
    state.cart.itemNames = state.cart.items.map(item => item.sessionId);
    state.cart.itemCount = state.cart.items.length;
    const cartItem = products[0].sessionId;
    const itemType = cartItem.startsWith('SUB') ? 'SUBSCRIPTION' : 'COURSE';
    const productList = [
      {
        id: products[0].courseId,
        name: products[0].title,
        category: itemType,
        variant: sessionId,
        brand: 'Summit',
        quantity: 1,
      },
    ];
    eecRemove(this.$gtm, productList);
    LocalStorageUtil.set('cart', state.cart);
  },
  updateAccount (state, newAccount) {
    state.account = { ...newAccount };
  },
  currentAccount (state, loadedUser) {
    state.account = { ...loadedUser };
  },
  updateBilling (state, newBilling) {
    state.billing = { ...newBilling };
  },
  // set the cart item with the data
  setItem (state, item) {
    const isAddWebinar = item.sessionId === 'SUBONLINEOAW';
    const newItem = item.sessionId === 'SUBONLINEOAW' ? { sessionId: 'SUBONLINE' } : item;
    console.log('setItems: ' + JSON.stringify(item));
    console.log(state.cart);
    const cartShouldBeEmpty = state.cart.itemCount === 0;
    console.log('cartShouldBeEmpty', cartShouldBeEmpty);
    const addingSubscription = newItem.sessionId.startsWith('SUB') && !newItem.sessionId.startsWith('SUB.');
    console.log('addingSubscription', addingSubscription);
    const cartContainsSubscription = state.cart.items[0]?.courseId === 'SUBSCRIPTION';
    console.log('cartContainsSubscription', cartContainsSubscription);

    const addingHep = newItem.sessionId.toLowerCase().includes('hep');
    console.log('Adding Hep?', addingHep);
    const cartContainsHep = state.cart.items[0]?.sessionId.toLowerCase().includes('hep');
    console.log('cartContainsHep', cartContainsHep);
    const allowTwoSubs = (addingHep && cartContainsSubscription) || (addingSubscription && cartContainsHep);
    console.log('Should I allow two subs? ', allowTwoSubs);

    if (!allowTwoSubs && (cartShouldBeEmpty || addingSubscription || cartContainsSubscription)) {
      state.cart.itemCount = 0;
      state.cart.items = [];
      state.cart.itemNames = [];
    }
    state.cart.items.push(newItem);
    state.cart.itemNames = state.cart.items.map(item => item.sessionId);
    const getAddons = isAddWebinar
      ? {
          id: 'ad8c21f2-e0ad-11e9-b405-0a721bb151c2',
          recurlyId: 'webinar',
          displayName: 'Summit Webinars',
          price: 1,
        }
      : {};
    if (isAddWebinar) {
      state.cart.items.item.push(getAddons);
    }
    state.cart.itemCount = state.cart.items.length;
    LocalStorageUtil.set('cart', state.cart);
  },
  // remove attendee from the cart item, don't want to remove from the main list of attendees
  // because we would need to check every cart item and see if it is being used anywhere else
  removeAttendee (state, data) {
    const personId = data.personId;
    const item = state.cart.items.find(({ sessionId }) => sessionId === data.sessionId);
    item.attendees = item.attendees.filter(attendee => attendee !== personId);
  },
  // add attendee to the cart item
  addAttendee (state, data) {
    state.cart.items[0].attendees.push(data);
  },
  // add attendee to the main attendees object
  setAttendee (state, data) {
    state.cart.attendee[data.personId] = data;
  },
  removeDiscount (state, discountCode) {
    state.cart.discountCodes = (state.cart.discountCodes || []).filter(code => code !== discountCode);
  },
  addDiscount (state, discountCode) {
    state.cart.discountCodes.push(discountCode);
  },
  updateCart (state, newCart) {
    state.cart = {
      ...newCart,
      'itemNames': newCart.items.map(item => item.sessionId),
    };
    LocalStorageUtil.set('cart', state.cart);
  },
};
