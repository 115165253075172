import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=0f610667&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavWhiteLogo: require('/var/lib/jenkins/workspace/Website Frontend - Production/components/nav/WhiteLogo.vue').default,TIcon: require('/var/lib/jenkins/workspace/Website Frontend - Production/components/app/Icon.vue').default,TSignupForm: require('/var/lib/jenkins/workspace/Website Frontend - Production/components/app/SignupForm.vue').default,TContainer: require('/var/lib/jenkins/workspace/Website Frontend - Production/components/app/Container.vue').default})
