export const state = () => ({
  fiveStarReviews: '400',
  ceuDelivered: '7',
  totalReviews: '550',
  // course counts per format
  inPersonPerYear: '500',
  inPersonCourses: '500',
  liveWebinars: '200',
  liveStreams: '50',
  video: '600',
  text: '200',
  podcast: 'Monthly',

  courses: '1,500',
  stars: '4.7/5',
  improvedPatientOutcomes: '92%',
  percentLongFormat: '85%',
  improvedPatientOutcomesPT: '86%',
  customerServiceSatisfaction: '97%',
  reportedNewSkillsLearned: '95%',
  reportedNewSkillsLearnedOT: '95%',
  instructorRating: '4.8/5',
  onlineCourses: '600',
  annualCredits: '2,200',
  podcasts: 'CE Podcast',
  ptCourses: '900',
  otCourses: '900',
  certificationSeries: '18',
  twoToSixHourCourses: '86',
  newCoursesPerYear: '400',
});

export const getters = {
  courseFormatCounts: state => (type) => {
    switch (type.toLowerCase()) {
      case 'live': return state.inPersonCourses;
      case 'webinar': return state.liveWebinars;
      case 'livestream': return state.liveStreams;
      case 'video': return state.video;
      case 'text': return state.text;
      case 'podcast': return state.podcast;
    }
  },
};
