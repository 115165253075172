import { experimentEvent, } from '@/services/gtmManager';

export const state = () => ({
  // define currently running A/B experiments
  experiments: [
    {
      name: 'cart-experiment',
      variants: ['A', 'B'],
    },
    {
      name: 'promo-cards',
      variants: ['A', 'B'],
    },
    {
      name: 'order-course-recommendation',
      variants: ['A', 'B'],
    },
    {
      name: 'hep-test-3',
      variants: ['A', 'B'],
    },
    {
      name: 'promo-short-cards',
      variants: ['A', 'B'],
    },
    {
      name: 'cart-company',
      variants: ['A', 'B'],
    },
  ],
});

export const getters = {
  experiments: state => state.experiments,
};

export const actions = {
  // determines which variant to show to the user
  getVariant ({ commit, state }, experimentName) {
    // if the cookie doesn't exist, create it and assign random variant
    if (!this.$cookies.get(`${experimentName}-experiment`)) {
      const experiment = state.experiments.find(
        experiment => experiment.name === experimentName
      );
      // if cookie name is promo-cards split variant 80/20 instead of 50/50
      const variant = experiment.variants[
        experimentName === 'promo-cards' ? Math.floor(Math.random() >= 0.80) : Math.floor(Math.random() * experiment.variants.length)
      ];

      this.$cookies.set(`${experimentName}-experiment`, variant, { secure: true });

      // send dataLayer event to GA
      experimentEvent(this.$gtm, experimentName, variant);

      return variant;
    }
    // if the cookies exists, just return the variant
    return this.$cookies.get(`${experimentName}-experiment`);
  },
};
